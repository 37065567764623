
import DataTable from '../../components/DataTable/DataTable';
import data from '../../legacyUsers'
import './leaderboard.css'



const columns =
    [
        { field: 'rank', headerName: 'rank', width: 90, type: 'number' },
        { field: 'username', headerName: 'Username', width: 160 },
        { field: 'wave', headerName: 'Wave', width: 150, type: 'number' },
        { field: 'score', headerName: 'Score', width: 150, type: 'number' },
        { field: 'deviceID', headerName: 'ID', width: 300 },
    ];


export default function Leaderboard1_1() {

    const rowID = function (row) {
        return row.deviceID;
    }
    return (
        <div className="leaderboard">
            <DataTable
                data={data.data}
                rowID={rowID}
                columns={columns}
            />
        </div>
    )
}

