import React from 'react'
import "./navbar.css"
import { Notifications, Settings} from '@material-ui/icons'

export default function Topbar() {
    return (
        <div className='navbar'>
            <div className="navbarWrapper">
                <div className="topLeft">
                    <span className='logo'>TTT Admin</span>
                </div>
                {/* <div className="topRight">
                    <div className="navbarIconContainer">
                        <Notifications />
                        <span className='navbarIconBadge'>3</span>
                    </div>
                    <div className="navbarIconContainer">
                        <Settings />
                    </div>
                </div> */}
            </div>
        </div>
    )
}
