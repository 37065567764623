import './leaderboard.css'
import { useEffect, useState } from 'react'
import { PostData, Request } from '../../RestUtils'
import Loader from '../../components/Loader';
import DataTable from '../../components/DataTable/DataTable';


const columns = [
    { field: 'rank', headerName: 'rank', width: 90, type: 'number' },
    { field: 'username', headerName: 'Username', width: 160 },
    { field: 'wave', headerName: 'Wave', width: 150, type: 'number' },
    { field: 'score', headerName: 'Score', width: 150, type: 'number' },
    { field: 'userID', headerName: 'ID', width: 300 },
];


export default function Leaderboard1_2() {

    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(true);



    useEffect(() => {

        async function fetchData() {
            var postReq = { "func": "GetLeaderboard" };
            const reqData = await PostData(Request.fetchApi1_2, postReq, false);
            setData(reqData)
            setLoading(false);
            return (reqData);
        }
        fetchData();

    }, []);

    const rowID = function (row) {
        return row.userID;
    }


    if (isLoading)
        return (<Loader />)
    else
        return (
            <div className="leaderboard">
                <DataTable
                    data={data}
                    rowID={rowID}
                    columns={columns}
                />
            </div>
        )
}
