import React from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { createContext, useState } from "react";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  border: '2px solid rgba(0, 0, 0, 0.385)',
  boxShadow: 24,

};

const ModalContext = createContext(false);


export const ImgModal =  ({ children })  => {

  const [open, setOpen] = useState(false);
  const [img, SetImg] = useState(null);
  const handleOpen = (imgData) => {
    setOpen(true);
    SetImg(imgData)
  }
  const handleClose = () => setOpen(false);

  return (

    <ModalContext.Provider value={{ handleOpen }}>
      {children}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <img className='previewImgLarge' src={`data:image/jpeg;base64,${img}`} />
        </Box>
      </Modal>
    </ModalContext.Provider>

  )
}
export default ModalContext;