import './users.css'
import Users1_3 from './Users1_3'
import {useParams } from "react-router-dom";
import Users1_2 from './Users1_2';
import Users1_1 from './Users1_1';
import TopBar from '../../components/TopBar';


export default function Users() {

    let params = useParams();
    let version = params.version;



    switch (version) {
        case "v1.1":
            return (
                <div className="users page">
                    <TopBar version={version ?? "v1.3"} path={"users"}/>
                    <Users1_1 />
                </div>
            );
        case "v1.2":
            return (
                <div className="users page">
                    <TopBar version={version ?? "v1.3"} path={"users"}/>
                    <Users1_2 />
                </div>
            );
        default:
            return (
                <div className="users page">
                    <TopBar version={version ?? "v1.3"} path={"users"} />
                    <Users1_3 />
                </div>
            )
    }
}
