import './users.css'
import { useEffect, useState } from 'react'
import { PostDataBody, PostData, Request } from '../../RestUtils'
import Loader from '../../components/Loader';
import DataTable from '../../components/DataTable/DataTable';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';






export default function Users1_3() {

  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const handleBlock = (id, banned) => {



    async function sendRequest() {
      var req = banned == 0 ? "BanUser" : "UnbanUser";
      var banReq = { "Request": req, "user": id };
      const reqData = await PostDataBody(Request.fetchApi1_3, banReq);
      return (reqData);
    }



    function changeUserState(userID, banned) {
      for (var i in data) {
        if (data[i].userID == userID) {
          data[i].banned = banned;
          break; //Stop this loop, we found it!
        }
      }
    }

    sendRequest();
    changeUserState(id, banned == 0 ? 1 : 0);
    setData(data);
  };

  const columns = [
    { field: 'userID', headerName: 'ID', width: 160 },
    { field: 'username', headerName: 'Username', width: 130 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'platform', headerName: 'Platform', width: 90, },
    { field: 'createDate', headerName: 'CreateDate', width: 160, },
    { field: 'lastLogin', headerName: 'LastLogin', width: 160, },
    {
      field: 'banned', headerName: 'Blocked', width: 60, hide: true,
      renderCell: (params) => {
        return (
          <div>
            {params.row.banned == 0 ? "false" : "true"}
          </div>
        );
      }
    }
    ,
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        const banned = params.row.banned ?? 0;
        return (
          <>

            <Link to={{ pathname: `/user/${params.row.userID}`, state: { id: 1, name: 'sabaoon', shirt: 'green' } }}>
              <button className="userListEdit">Edit</button>
            </Link>
            <button
              className={banned == 0 ? "userListBan" : "userListUnban"}
              onClick={() => handleBlock(params.row.userID, params.row.banned ?? 0)}
            >  {banned == 0 ? "Block" : "Unblock"} </button>
          </>
        );
      },
    },
  ];





  useEffect(() => {

    async function fetchData() {
      var postReq = { "Request": "GetAllUsers" };
      const reqData = await PostData(Request.fetchApi1_3, postReq);
      setData(reqData)
      setLoading(false);
      return (reqData);
    }
    fetchData();

  }, []);

  const rowID = function (row) {
    return row.userID;
  }

  if (isLoading)
    return (<Loader />)
  else
    return (
      <div className='users'>
        <DataTable
          data={data}
          rowID={rowID}
          columns={columns}
        />
      </div>
    )

}



