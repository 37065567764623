import './users.css'
import { useEffect, useState } from 'react'
import { PostData, Request } from '../../RestUtils'
import Loader from '../../components/Loader';
import DataTable from '../../components/DataTable/DataTable';




const columns = [
    { field: 'userID', headerName: 'ID', width: 90 },
    { field: 'username', headerName: 'Username', width: 130 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'platform', headerName: 'Platform', width: 90, },
    { field: 'createDate', headerName: 'CreateDate', width: 160, },
    { field: 'lastLogin', headerName: 'LastLogin', width: 160, type: 'date' },
    { field: 'playTime', headerName: 'PlayTime', width: 160, type: 'date' },
    { field: 'loginTracker', headerName: 'LoginTracker', width: 100, type: 'number' },
    // { field: 'guestAccount', headerName: 'GuestAccount', width: 100,  type: 'boolean',},
];


export default function Users1_2() {

    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(true);


    useEffect(() => {

        async function fetchData() {
            var postReq = { "func": "GetAllUsers" };
            const reqData = await PostData(Request.fetchApi1_2, postReq, false);
            setData(reqData)
            setLoading(false);
            return (reqData);
        }
        fetchData();

    }, []);

    const rowID = function (row) {
        return row.userID;
    }

    if (isLoading)
        return (<Loader />)
    else
        return (
            <div className='users'>
                <DataTable
                    data={data}
                    columns={columns}
                    rowID={rowID}
                />
            </div>
        )
}
