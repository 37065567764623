import { createContext, useState, useEffect} from "react";

const AuthContext = createContext(false);

export const AuthProvider = ({ children }) => {

    const [auth, setAuth] = useState(JSON.parse(window.sessionStorage.getItem("auth")));

    useEffect(() => {
        setAuth(JSON.parse(window.sessionStorage.getItem("auth")));
      }, []);
    useEffect(() => {
        window.sessionStorage.setItem("auth", auth);
    }, [auth]);
    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;