import './maps.css'
import { useEffect, useState, useContext } from 'react'
import { PostData, Request } from '../../RestUtils'
import Loader from '../../components/Loader';
import DataTable from '../../components/DataTable/DataTable';
import ModalContext from '../../components/Modal/ImgModal';
import "../../components/topbarStyle.css"
import { Link, useParams } from "react-router-dom";

export default function Maps() {



    let params = useParams();
    let version = params.version;

    const { handleOpen } = useContext(ModalContext);
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [stage,setStage]= useState(version)
    //setLoading(true)

    const handlePreview = function (imgData) {
        handleOpen(imgData);
    }

    const columns = [
        {
            field: 'previewImg', headerName: 'Preview', width: 160, sortable: false,
            renderCell: (params) => {
                return (
                    <img
                        className='previewImgSmall'
                        src={`data:image/jpeg;base64,${params.value}`}
                        onClick={() => handlePreview(params.value)}
                    />
                );
            }
        },
        { field: 'mapName', headerName: 'Name', width: 160 },
        { field: 'size', headerName: 'size', width: 90 },
        { field: 'waves', headerName: 'waves', width: 90 },
        { field: 'createDate', headerName: 'createDate', width: 160, type: 'dateTime' },
        { field: 'lastEdit', headerName: 'lastEdit', width: 160, type: 'dateTime' },
        { field: 'ownerUsername', headerName: 'ownerUsername', width: 160 },
        { field: 'version', headerName: 'version', width: 60, type: 'number' },
        { field: 'downloadCount', headerName: 'downloadCount', width: 90, type: 'number' },
        { field: 'ownerID', headerName: 'ownerID', width: 160 },
        { field: 'mapInstanceID', headerName: 'ID', width: 90 },
        {
            field: "action",
            headerName: "Action",
            width: 150,
            renderCell: (params) => {
                //   const banned = params.row.banned ?? 0;
                return (
                    <>
                        <button className="download-btn" onClick={() => ExportData(params.row.mapName, params.row.mapInstanceID,stage)}>
                            Download
                        </button>
                        <button className="delete-btn" onClick={() => OnDelete(params.row.mapInstanceID,stage)} >
                            Delete
                        </button>
                        {/* <Link to={{ pathname: `/user/${params.row.userID}`, state: { id: 1, name: 'sabaoon', shirt: 'green' } }}>
                    <button className="userListEdit">Edit</button>
                  </Link>
                  <button
                    className={banned == 0 ? "userListBan" : "userListUnban"}
                    onClick={() => handleBlock(params.row.userID, params.row.banned ?? 0)}
                  >  {banned == 0 ? "Block" : "Unblock"} </button> */}
                    </>
                );
            },
        },
    ];

    function ExportData(mapname, mapID,stage) {

        async function DownloadMap() {
            var req = {
                "Request": "DownloadMap","mapInstanceID":mapID,"Stage": stage??"live"
            };
            const reqData = await PostData(Request.fetchApi1_3, req);

            
            const mapData = reqData[0]["mapData"];
            reqData[0]["mapData"] = JSON.parse(mapData);

            const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
                JSON.stringify(reqData[0])
            )}`;
            const link = document.createElement("a");
            link.href = jsonString;
            link.download = `${mapname}.json`;
            link.click();
            return(reqData)
        }
        DownloadMap();
    }

    function OnDelete(mapID,stage) {
        console.log(`Delete ${mapID}`)
        setLoading(true);
        async function DeleteMap(){
            var req = {
                "Request": "DeleteMap","mapInstanceID":mapID,"Stage": stage??"live"
            };   
            await PostData(Request.fetchApi1_3, req);
            var postReq = {
                "Request": "FetchMaps",
                "Stage": stage?? "live"
            };
            const reqData = await PostData(Request.fetchApi1_3, postReq);
            setData(reqData)
            setLoading(false);
            return (reqData);
        }
        DeleteMap();
    }



    useEffect(() => {
        setLoading(true);
        async function fetchData() {
            var postReq = {
                "Request": "FetchMaps",
                "Stage": version
            };
            const reqData = await PostData(Request.fetchApi1_3, postReq);
            setData(reqData)
            setLoading(false);
            setStage(version)
            return (reqData);
        }
        fetchData();

    }, [version]);


    const rowID = function (row) {
        return row.mapInstanceID;
    }
    return (
        <>
            <div className="Maps page">
                <StageBar version={version ?? "live"} path={"maps"} />
                {isLoading ? (<Loader />) :
                    (
                        <DataTable
                            data={data}
                            columns={columns}
                            rowID={rowID}
                        />
                    )
                }
            </div>
        </>);

}



function StageBar({ version, path, setLoading }) {
    // const path = "maps"
    // const version = "live"
    return (
        <div className="usersTopbar">
            <Link className="Link" to={`/${path}/live`} key={"live"}>
                <div className={`topbarItem ${version === "live" ? "active" : ""}`}>
                    <span className="timeTitle">
                        Live
                    </span>
                </div>
            </Link>
            <Link className="Link" to={`/${path}/dev`} key={"dev"}>
                <div className={`topbarItem ${version === "dev" ? "active" : ""}`}>
                    <span className="timeTitle">
                        Dev
                    </span>
                </div>
            </Link>
        </div>

    );
}
