
const baseUrl = 'https://leaderboard.twotimintowers.com/'

export async function PostData(url, data = {}, json = true) {

  const response = await fetch(`${baseUrl}${url}`, {
    method: 'POST',
    credentials: "same-origin",
    headers: {
      "Content-Type": json ? "application/json" : 'application/x-www-form-urlencoded;charset=UTF-8'
    },
    body: json ? JSONStringify(data) : FormBody(data)
  });
  console.log(response);
  return response.json();
}

export async function PostDataBody(url, data = {}, json = true) {

  const response = await fetch(`${baseUrl}${url}`, {
    method: 'POST',
    credentials: "same-origin",
    headers: {
      "Content-Type": json ? "application/json" : 'application/x-www-form-urlencoded;charset=UTF-8'
    },
    body: json ? JSONStringify(data) : FormBody(data)
  });
  return response;
}

function FormBody(details) {
  var formBody = [];
  for (var property in details) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  return formBody;
}
function JSONStringify(data) {
  return JSON.stringify(data);
}


export const Request = {

  fetchApi1_3: 'MapMaker/UserManager.php',
  fetchApi1_2: 'tttUserManagerAPI.php',

};
