import Navbar from "./components/navbar/Navbar";
import Sidebar from "./components/sidebar/Sidebar";
import Home from "./pages/Home/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom"
import "./pages/app.css"
import Users from "./pages/Users/Users";
import Leaderboard from "./pages/Leaderboard/Leaderboard";
import Login from "./pages/Login/Login";
import { useContext} from "react";
import AuthContext from './Context/AuthProvider';
import Reports from "./pages/Reports/Reports";
import Localization from "./pages/Localization/Localization";
import Maps from "./pages/Maps/Maps";
import User from "./pages/Users/User";
import Feedback from "./pages/Feedback/Feedback";
// import { DataGrid } from '@mui/x-data-grid';

function App() {

  const { auth } = useContext(AuthContext);
  return (
    <BrowserRouter>
      {
        !auth ? (<Login />)
          : (
            <>
              <Navbar />
              <div className="container">
                <Sidebar />
                <Routes>

                  <Route exact path="/" element={
                    <Home />
                  }>
                  </Route >

                  <Route path="users" element={<Users />}>
                    <Route path=":version" element={<Users />} />
                    <Route path=":tab" />
                  </Route>

                  <Route path="leaderboard" element={<Leaderboard />}>
                    <Route path=":version" element={<Leaderboard />} />
                    <Route path=":tab" />
                  </Route>

                  <Route path="reports" element={<Reports />}></Route>
                  <Route path="localization" element={<Localization />}></Route>
                  <Route path="Feedback" element={<Feedback />}></Route>
                  <Route path="maps" element={<Maps />}>
                    <Route path=":version" element={<Maps />} />
                    <Route path=":tab" />
                  </Route>
                  <Route name = "user" path="user/:userID" element={<User/>}></Route>
                </Routes>
              </div>
            </>
          )
      }
    </BrowserRouter>
  );
}

export default App;
