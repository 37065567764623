import "./localization.css"

export default function Localization() {
    return (
        <div className="localization page">
            <iframe className='sheetIframe' src={"https://docs.google.com/spreadsheets/d/e/2PACX-1vSo1FtYtiHQp87F1xZ0OAudMN3tzuJeVWZ-ClPf7rgDWIOaqyVvqZgpJMQ-XmM5-s6NF_-wRxalti3G/pubhtml?gid=0&amp;single=true&amp;widget=true&amp;headers=false"}>
            </ iframe>
        </div>
    )
}
