import './reports.css'
import { useEffect, useState } from 'react'
import { PostData, Request } from '../../RestUtils'
import Loader from '../../components/Loader';
import DataTable from '../../components/DataTable/DataTable';


const columns = [
    { field: 'report_id', headerName: 'id', width: 90 },
    { field: 'mapInstanceID', headerName: 'mapID', width: 160 },
    { field: 'mapName', headerName: 'mapName', width: 150 },
    { field: 'ownerID', headerName: 'mapUserID', width: 150 },
    { field: 'Owner', headerName: 'mapUsername', width: 150 },
    { field: 'reporterID', headerName: 'reporterUserID', width: 150 },
    { field: 'reporterName', headerName: 'reporterUsername', width: 150 },
    { field: 'report', headerName: 'report', width: 400 ,editable:true },
    { field: 'reportDate', headerName: 'reportDate', width: 150 },
];

export default function Reports() {

    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(true);



    useEffect(() => {

        async function fetchData() {
            var postReq = { "Request": "Reports" };
            const reqData = await PostData(Request.fetchApi1_3, postReq);
            setData(reqData)
            setLoading(false);
            return (reqData);
        }
        fetchData();

    }, []);

    const rowID = function (row) {
        return row.report_id;
    }

    return (
        <>
            <div className="Reports page">
                {isLoading ? (<Loader />) :
                    (
                        <DataTable
                            data={data}
                            columns={columns}
                            rowID={rowID}
                        />
                    )
                }
            </div>
        </>
    )
}
