import './user.css';
import {  useParams, useLocation } from "react-router-dom";

import { useEffect, useState, useContext } from 'react'
import {PostDataBody, PostData, Request } from '../../RestUtils'
import Loader from '../../components/Loader';
import DataTable from '../../components/DataTable/DataTable';
import ModalContext from '../../components/Modal/ImgModal';


export default function User() {

  let { userID } = useParams();
  const location = useLocation();



  const [isLoading, setLoading] = useState(true);
  const [mapsData, setMapData] = useState([]);
  const [userData, setUserData] = useState([]);
  const { handleOpen } = useContext(ModalContext);


  const handleBlock = async (id, banned) => {



    async function sendRequest() {
      var req = banned == 0 ? "BanUser" : "UnbanUser";
      var banReq = { "Request": req, "user": id };
      const reqData = await PostDataBody(Request.fetchApi1_3, banReq);
      return (reqData);
    }





    userData.banned = banned == 0 ? 1 : 0;
    setUserData(userData);
    sendRequest();
  };


  const handlePreview = function (imgData) {
    handleOpen(imgData);
  }

  const columns = [
    {
      field: 'previewImg', headerName: 'Preview', width: 160, sortable: false,
      renderCell: (params) => {
        return (
          <img
            className='previewImgSmall'
            src={`data:image/jpeg;base64,${params.value}`}
            onClick={() => handlePreview(params.value)}
          />
        );
      }
    },
    { field: 'mapName', headerName: 'Name', width: 160 },
    { field: 'downloadCount', headerName: 'downloadCount', width: 90, type: 'number' },
    { field: 'size', headerName: 'size', width: 90 },
    { field: 'waves', headerName: 'waves', width: 90 },
    { field: 'createDate', headerName: 'createDate', width: 160, type: 'dateTime' },
    { field: 'lastEdit', headerName: 'lastEdit', width: 160, type: 'dateTime' },
    { field: 'version', headerName: 'version', width: 60, type: 'number' },
    { field: 'mapInstanceID', headerName: 'ID', width: 90 },
  ];

  useEffect(() => {

    async function fetchData() {
      var postReq = { "Request": "GetUserMaps", "user": userID };
      const reqData = await PostData(Request.fetchApi1_3, postReq);
      const userPostReq = { "Request": "GetUser", "user": userID };
      const reqUserData = await PostData(Request.fetchApi1_3, userPostReq);

      setMapData(reqData)
      setUserData(reqUserData)
      setLoading(false);
      return (reqData);
    }
    fetchData();

  }, []);

  const rowID = function (row) {
    return row.mapInstanceID;
  }






  // banned
  return (

    <>
      <div className="User page">
        {isLoading ? (<Loader />) :
          (
            <>
              <div className="userTitleContainer">
                <h1 className="userTitle">Edit User</h1>
                <button
                  className={`userBlockButton ${userData.banned == 1 ? "blocked" : ""}`}
                  onClick={() => handleBlock(userData.userID, userData.banned ?? 0)}
                >
                  {userData.banned == 1 ? "Unblock" : "Block"}
                </button>
              </div>

              <div className="userContainer">
                <div className="userInfoContainer">
                  <UserInfo label="Username" value={userData.username} />
                  <UserInfo label="User ID" value={userData.userID} />
                  <UserInfo label="Email" value={userData.email} />
                  <UserInfo label="Platform" value={userData.platform} />
                  <UserInfo label="Create Date" value={userData.createDate} />
                  <UserInfo label="Last Play" value={userData.lastLogin} />
                </div>

                <div className="userMaps">
                  <DataTable
                    data={mapsData}
                    columns={columns}
                    rowID={rowID}
                  />
                </div>
              </div>
            </>
          )}
      </div>
    </>);
}

const UserInfo = ({ label, value }) => {

  return (
    <div className="userInfo">
      <span className="userInfoLabel">{label}:</span>
      <span className="userInfoValue">{value}</span>
    </div>
  )

}
