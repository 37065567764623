import "./topbarStyle.css"
import { Link } from "react-router-dom";


export default function TopBar({ version, path }) {
    return (
        <div className="usersTopbar" >
            <Link className="Link" to={`/${path}/v1.3`} key={"v1.3"}>
                <div className={`topbarItem ${version === "v1.3" ? "active" : ""}`}>
                    <span className="timeTitle">
                        V1.3
                    </span>
                </div>
            </Link>
            <Link className="Link" to={`/${path}/v1.2`} key={"v1.2"}>
                <div className={`topbarItem ${version === "v1.2" ? "active" : ""}`}>
                    <span className="timeTitle">
                        V1.2
                    </span>
                </div>
            </Link>
            <Link className="Link" to={`/${path}/v1.1`} key={"v1.1"}>
                <div className={`topbarItem ${version === "v1.1" ? "active" : ""}`}>
                    <span className="timeTitle">
                        V1.1
                    </span>
                </div>
            </Link>
        </div >
    );
}
