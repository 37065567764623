import React from 'react'
import './sidebar.css'
import { NavLink,  Outlet, useParams } from "react-router-dom";


export default function Sidebar() {

  let params = useParams();
  let param = params.key;


  function className(active) {
    return `sidebarListItem ${active ? "active" : ""}`;
  }


  return (
    <div className="sidebar">
      <div className="sidebarWrapper">
        <div className="sidebarMenu">
          {/* Dashboard */}
          <h3 className="sidebarTitle">Dashboard</h3>
          <ul className='sidebarList'>
            {/* <NavLink  to={"/"} text="Home" />
            <MenuItemExt to={"/users"} text="Users" /> */}

            <NavLink to={"/"} className="Link" children={({ isActive }) => <li className={className(isActive)}>Home</li>} />
            <NavLink to={"/users"} className="Link" children={({ isActive }) => <li className={className(isActive)}>Users</li>} />
            <NavLink to={"/leaderboard"} className="Link" children={({ isActive }) => <li className={className(isActive)}>Leaderboard</li>} />
            <NavLink to={"/localization"} className="Link" children={({ isActive }) => <li className={className(isActive)}>Localization</li>} />
            <NavLink to={"/feedback"} className="Link" children={({ isActive }) => <li className={className(isActive)}>Feedback</li>} />
            {/* <NavLink to={"/log"} className="Link" children={({ isActive }) => <li className={className(isActive)}>Log</li>} /> */}
          </ul>
        </div>
        {/* MapMaker */}
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Map Maker</h3>
          <ul className='sidebarList'>
          <NavLink to={"/reports"} className="Link" children={({ isActive }) => <li className={className(isActive)}>Reports</li>} />
          <NavLink to={"/maps"} className="Link" children={({ isActive }) => <li className={className(isActive)}>Maps</li>} />
          </ul>
        </div>
        {/* StoryEditor */}
        <div className="sidebarMenu">
          <h3 className="sidebarTitle">Story Editor</h3>
          <ul className='sidebarList'>
            <li className="sidebarListItem">
              StoryMaker
            </li>
            <li className='sidebarListItem'>
              Log
            </li>
          </ul>
        </div>
      </div>
      <Outlet />
    </div>
  )
}


// const MenuItemExt = ({ text, to }) => {

//   function className(active) {
//     console.log(active)
//     return `sidebarListItem ${active ? "active" : ""}`;
//   }

//   return (
//     <NavLink
//       className="Link"
//       to={to}
//       children={({ match, history }) => (
//         <li
//           className={className(match)}>
//           {text}
//         </li>
//       )}
//     />
//   );
// };
