import './leaderboard.css'
import TopBar from '../../components/TopBar'
import { useParams } from "react-router-dom";
import Leaderboard1_3 from './Leaderboard1_3'
import Leaderboard1_2 from './Leaderboard1_2'
import Leaderboard1_1 from './Leaderboard1_1';


export default function Leaderboard() {
    let params = useParams();
    let version = params.version;

    
    switch (version) {
        case "v1.1":
            return (
                <div className="users page">
                    <TopBar version={version ?? "v1.3"} path={"leaderboard"}/>
                    <Leaderboard1_1 />
                </div>
            );
        case "v1.2":
            return (
                <div className="users page">
                    <TopBar version={version ?? "v1.3"} path={"leaderboard"}/>
                    <Leaderboard1_2 />
                </div>
            );
        default:
            return (
                <div className="users page">
                    <TopBar version={version ?? "v1.3"} path={"leaderboard"} />
                    <Leaderboard1_3 />
                </div>
            )
    }
}
