import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ImgModal } from './components/Modal/ImgModal';
import { AuthProvider } from './Context/AuthProvider';

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <ImgModal>
        <App />
      </ImgModal>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
