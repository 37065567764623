import './login.css'
import { useRef, useState, useEffect, useContext } from 'react';
import { PostData, Request } from '../../RestUtils'
import AuthContext from "../../Context/AuthProvider";
import Loader from '../../components/Loader';

export default function Login() {


    const { setAuth } = useContext(AuthContext);

    const userRef = useRef();
    const errRef = useRef();
    const accRef = useRef();


    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [isRequesting, SetIsRequesting] = useState(false);


    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    const handleSubmit = async (e) => {
        e.preventDefault();
        SetIsRequesting(true)
        try {
            var postReq = { "Request": "Auth", "user": user, "psw": pwd };
            const reqData = await PostData(Request.fetchApi1_3, postReq);
            setAuth(reqData.auth);
            setUser('');
            setPwd('');
            setSuccess(true);
        } catch (err) {
            SetIsRequesting(false)
        }

    };
    return (
        <div className="login">
            <div className="bg"></div>
            <div className="loginContainer">
                {
                isRequesting ? (<Loader />) :
                 (
                    <div className="account">
                        <form className="loginform" onSubmit={handleSubmit}>
                            <div className="rlogo">TTT Admin Panel</div>

                            <label htmlFor="username">Username:</label>
                            <input
                                type="text"
                                id="username"
                                ref={userRef}
                                autoComplete="off"
                                onChange={(e) => setUser(e.target.value)}
                                value={user}
                                required
                            />


                            <label htmlFor="password">Password:</label>
                            <input
                                type="password"
                                id="password"
                                onChange={(e) => setPwd(e.target.value)}
                                value={pwd}
                                required
                            />
                            <button className='login_btn'>Sign In</button>
                        </form>
                    </div>)
                }
            </div>
        </div>
    )
}
