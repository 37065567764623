import './users.css'
import data from '../../legacyUsers'
import DataTable from '../../components/DataTable/DataTable';



const columns = [
    { field: 'username', headerName: 'Username', width: 160 },
    { field: 'email', headerName: 'Email', width: 250 },
    { field: 'deviceID', headerName: 'ID', width: 300 },
];

export default function Users1_1() {

    const rowID = function (row){
        return row.deviceID;
    }

    return (
        <div className='users'>
            <DataTable data={data.data} columns={columns} rowID={rowID} />
        </div>
    )
}


